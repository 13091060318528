@tailwind base;
@tailwind components;
@tailwind utilities;
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.2/css/all.min.css);
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

body {
  margin: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  background: linear-gradient(90deg, rgba(83,29,233,0.5858718487394958) 0%, rgba(32,69,154,0.8099614845938375) 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  max-width: 1280px;
  margin-inline: auto;
  padding-inline: 10px;
}
